// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-coverage-tsx": () => import("./../../../src/pages/about-us/coverage.tsx" /* webpackChunkName: "component---src-pages-about-us-coverage-tsx" */),
  "component---src-pages-about-us-history-tsx": () => import("./../../../src/pages/about-us/history.tsx" /* webpackChunkName: "component---src-pages-about-us-history-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-interior-02-tsx": () => import("./../../../src/pages/case-studies/interior-02.tsx" /* webpackChunkName: "component---src-pages-case-studies-interior-02-tsx" */),
  "component---src-pages-case-studies-interior-tsx": () => import("./../../../src/pages/case-studies/interior.tsx" /* webpackChunkName: "component---src-pages-case-studies-interior-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-reset-password-tsx": () => import("./../../../src/pages/login/reset-password.tsx" /* webpackChunkName: "component---src-pages-login-reset-password-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-interior-tsx": () => import("./../../../src/pages/news/interior.tsx" /* webpackChunkName: "component---src-pages-news-interior-tsx" */)
}

